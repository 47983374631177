import React from 'react'
import Style from "styled-components";
import { ThemeContext } from "styled-components";
import { CardSize, ColorMode, ColorStyle, FontStyle, FontType } from '../../utils/types/types'
import Label from '../Label';

interface CardProps {
    size: CardSize;
    title: string;
    body: string;
    imagePath?: string;
}

const Card = ({ size, title, body, imagePath }: CardProps) => {
    const theme = React.useContext(ThemeContext);
    const style: React.CSSProperties = {
        objectFit: 'cover',
        width: 'inherit',
    }
    return (
        <Container size={size} theme={theme}>
            {imagePath ? <img alt="card illustration" style={style} src={imagePath} height={theme.cardImageHeight[size]}></img> : null}
            <Content size={size} theme={theme}>
                <Label text={title} type={FontType.title} fontStyle={FontStyle.header3} color={ColorStyle.title} mode={ColorMode.light} align='left'></Label>
                <Label text={body} type={FontType.body} fontStyle={FontStyle.body} color={ColorStyle.body} mode={ColorMode.light} align='left'></Label>
            </Content>
        </Container>
    )
}

interface ContentProps {
    size: CardSize;
    theme: object;
}

const Container = Style.div<ContentProps>`
    margin: ${props => {
        switch (props.size) {
            case CardSize.large:
                return `${props.theme.spacing.medium}`;
            case CardSize.normal:
                return `${props.theme.spacing.small}`;
        }
    }};
    max-width: ${props => props.theme.cardWidth[props.size]};
    background: ${props => props.theme.color.foreground};
    position: relative;
    display: inline-block;
    overflow: hidden;
    ${props => props.theme.shadow.light};
    border-radius: ${props => props.theme.borderRadius.regular};
    transition: 0.3s;
    z-index: 10;
    &:hover {
        transform: scale(1.02);
        ${props => props.theme.shadow.focused};
    }
`;

const Content = Style.div<ContentProps>`
    padding: ${props => {
        switch (props.size) {
            case CardSize.large:
                return `${props.theme.spacing.large}`;
            case CardSize.normal:
                return `${props.theme.spacing.medium}`;
        }
    }};
`;

export default Card;
