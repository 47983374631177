export const Icon = () => (
    <svg width="80" height="85" viewBox="0 0 80 85" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d)">
            <path d="M39.7461 24C38.7471 24 38.0508 24.6963 38.0508 25.6953V44.3892L38.187 47.6738L34.2515 43.2842L30.8154 39.8633C30.5127 39.5605 30.0889 39.3638 29.6045 39.3638C28.6812 39.3638 28 40.0601 28 41.0137C28 41.4526 28.1665 41.8613 28.5298 42.2246L38.4897 52.2148C38.8228 52.563 39.2769 52.7446 39.7461 52.7446C40.2002 52.7446 40.6543 52.563 40.9873 52.2148L50.9624 42.2246C51.3257 41.8613 51.4922 41.4526 51.4922 41.0137C51.4922 40.0601 50.7959 39.3638 49.8726 39.3638C49.4033 39.3638 48.9795 39.5605 48.6768 39.8633L45.2256 43.2842L41.3052 47.6587L41.4263 44.3892V25.6953C41.4263 24.6963 40.73 24 39.7461 24Z" fill="white" />
        </g>
        <defs>
            <filter id="filter0_d" x="0" y="0" width="79.4922" height="84.7446" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="14" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
            </filter>
        </defs>
    </svg>
)