import React from 'react'
import Style from "styled-components";
import { ThemeContext } from "styled-components";

interface PageOpaqueContainerProps {
    children: any;
}

const PageOpaqueContainer = ({ children }: PageOpaqueContainerProps) => {
    const theme = React.useContext(ThemeContext);
    return (
        <Container theme={theme}>
            {children}
        </Container>
    )
}

interface ContentProps {
    theme: object;
}

const Container = Style.div<ContentProps>`
    background: ${props => props.theme.color.background};
`;

export default PageOpaqueContainer;
