import Card from '../../components/Card';
import SectionHeader from '../../components/SectionHeader';
import { CardSize, ColorMode, FontStyle } from '../../utils/types/types';

const OurTeamSection = () => (
    <>
        <SectionHeader title='we_are_passionate.header.title' subtitle='we_are_passionate.header.subtitle' titleStyle={FontStyle.header2} mode={ColorMode.light}></SectionHeader>
        <div className="horizontalStack">
            <Card size={CardSize.normal} title='we_are_passionate.1.title' body='we_are_passionate.1.description' imagePath='./assets/cover/we_are_passionate.1.jpg'></Card>
            <Card size={CardSize.normal} title='we_are_passionate.2.title' body='we_are_passionate.2.description' imagePath='./assets/cover/we_are_passionate.2.jpg'></Card>
            <Card size={CardSize.normal} title='we_are_passionate.3.title' body='we_are_passionate.3.description' imagePath='./assets/cover/we_are_passionate.3.jpg'></Card>
        </div>
    </>
);

export default OurTeamSection;
