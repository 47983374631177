import Label from "../../components/Label";
import { ColorMode, ColorStyle, FontStyle, FontType } from "../../utils/types/types";
import Style from "styled-components";

const FooterSection = () => {
    return (
        <FooterContainer>
            <Label type={FontType.body} fontStyle={FontStyle.info} text='footer.copyright' color={ColorStyle.info} mode={ColorMode.light} align='center' margin='0'></Label>
        </FooterContainer>
    )
};

const FooterContainer = Style.div`
    padding: 24px 0;
`;

export default FooterSection;