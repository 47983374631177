import Card from '../../components/Card';
import { CardSize } from '../../utils/types/types';
import Style, { ThemeContext } from "styled-components";
import { useContext } from 'react';

const OurMission = () => {
    const theme = useContext(ThemeContext);

    return (
        <Stack>
            <HorizontalStack theme={theme}>
                <Card size={CardSize.large} title='what_we_do.1.title' body='what_we_do.1.description' />
                <Card size={CardSize.large} title='what_we_do.2.title' body='what_we_do.2.description' />
            </HorizontalStack>
        </Stack>
    )
};

interface ContentProps {
    theme: object;
}

const Stack = Style.div`
    background-color: white; //TODO
`

const HorizontalStack = Style.div<ContentProps>`
    flex-wrap: wrap;
    justify-content: center;
    margin: ${p => p.theme.spacing.section} 0px -100px 0px;
    width: 100%;
    display: flex;
    top: -100px;
    position: relative;
`;

export default OurMission;
