import React, { useState } from 'react';
import styled, { ThemeContext } from "styled-components";
import debounceFn from 'debounce-fn';

const BlurredOverlayer = () => {

    const theme = React.useContext(ThemeContext);

    const [blurAmount, setBlurAmount] = useState<number>(0);

    const blurAnimationParameters: AnimationParameters = {
        scrollStart: 10.0,
        scrollEnd: 300.0,
        amountStart: 0.0,
        amountEnd: 15.0,
        curveFunction: easeOutCurve
    }

    React.useLayoutEffect(() => {
        const updatePosition = () => {
            setBlurAmount(getAnimationAmount(window.scrollY, blurAnimationParameters))
        }
        const animate = debounceFn(updatePosition, { wait: 12 })
        window.addEventListener('scroll', animate);
        animate();
        return () => window.removeEventListener('scroll', animate);
    });

    return (
        <>
            <BackgroundOverlayer theme={theme} blur={`blur(${blurAmount}px)`} />
        </>
    );
}

interface AnimationParameters {
    scrollStart: number;
    scrollEnd: number;
    amountStart: number;
    amountEnd: number;
    curveFunction: Function;
}

function getAnimationAmount(scroll: number, animationParameters: AnimationParameters) {
    const progress = (scroll - animationParameters.scrollStart) / (animationParameters.scrollEnd - animationParameters.scrollStart);
    if (progress <= 0) {
        return animationParameters.amountStart
    } else if (progress >= 1) {
        return animationParameters.amountEnd
    } else {
        return animationParameters.amountStart + animationParameters.curveFunction(progress) * (animationParameters.amountEnd - animationParameters.amountStart)
    }
}

function easeOutCurve(progress: number) {
    return Math.atan(Math.PI * progress) / Math.atan(Math.PI)
}

interface ItemProps {
    blur?: string;
    imagePath?: string;
    transform?: string;
    theme?: object;
}

const BackgroundOverlayer = styled.div.attrs<ItemProps>(
    ({ blur }) => ({ style: { 'WebkitBackdropFilter': blur, 'backdropFilter': blur } })) <ItemProps>`
    background: ${props => props.theme.color.overlayer};
    z-index: -11;
    top: -50px;
    will-change: transform;
    width: 120%;
    height: 120vh;
    position: fixed;
`;

export default BlurredOverlayer;
