import React from 'react'
import Style from "styled-components";
import { ThemeContext } from "styled-components";
import { ColorMode, ColorStyle, FontStyle, FontType } from '../../utils/types/types'
import Label from '../Label';

interface FooterCardProps {
    iconPath: string,
    title: string;
    body: string;
    children?: any;
}

const FooterCard = ({iconPath, title, body, children}: FooterCardProps) => {
    const theme = React.useContext(ThemeContext);
    const style: React.CSSProperties = {
        objectFit: 'fill',
    }
    return (
    <Container theme={theme}>
        <img alt="card-illustration" src={iconPath} style={style} width={theme.iconDiameter} height={theme.iconDiameter}></img>
        <Label text={title} margin={`${theme.spacing.medium} 0px 0px 0px`} type={FontType.title} fontStyle={FontStyle.header3} color={ColorStyle.title} mode={ColorMode.light} align='center'>{children}</Label>
        <Label text={body} type={FontType.body} fontStyle={FontStyle.body} color={ColorStyle.body} mode={ColorMode.light} align='center'>{children}</Label>
    </Container>
    )
}

interface ContentProps {
    theme: object;
}

const Container = Style.div<ContentProps>`
    max-width: ${props => props.theme.footerCardWidth};
    /* Auto Layout */

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    /* Inside Auto Layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: ${props => props.theme.spacing.medium} ${props => props.theme.spacing.large};
`;

export default FooterCard;
