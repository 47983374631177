import React from 'react';
import { ColorStyle, FontType, FontStyle, ColorMode } from '../../utils/types/types';
import Style from "styled-components";
import styled, { ThemeContext } from "styled-components";
import Label from '../../components/Label';
import { Icon } from '../../components/Icon';
import { HashLink } from 'react-router-hash-link';
import TextLoop from "react-text-loop";
import FloatingButton from '../../components/FloatingButton';
import BlurredOverlayer from './BlurredOverlayer';
import ImageAnimationLayer from './ImageAnimationLayer';
import OpacityLayer from './OpacityLayer';

const WelcomeSection = () => {

    const theme = React.useContext(ThemeContext);

    return (
        <>
            <BackgroundContainer>
                <ImageAnimationLayer imagePath={"./assets/background/big_title_cover.jpg"}>
                    <BlurredOverlayer />
                </ImageAnimationLayer>
            </BackgroundContainer>
            <OpacityLayer>
                <Logo alt="Logo Creastel" src="./assets/logos/large.png" />
                <HashLink smooth to="/#who-we-are">
                    <Description>
                        <Label text='welcome.go_down.description' margin={`${theme.spacing.medium} 0px 0px 0px`} type={FontType.body} fontStyle={FontStyle.header4} color={ColorStyle.overImage} mode={ColorMode.light} align='center'>...<i>experiences</i>...</Label>
                        <Icon />
                    </Description>
                </HashLink>
            </OpacityLayer>
            {theme.desktop && <FloatingButton title="welcome.hiring.button" link="https://krxqh064rgu.typeform.com/to/zHhympCF" iconPath="./assets/icons/hiring.png" />}
            <TitleContainer>
                <Title>
                    <Label text='welcome.title' margin={`0px 0px 0px 0px`} type={FontType.title} fontStyle={FontStyle.header1} color={ColorStyle.overImage} mode={ColorMode.light} align='center' padding={`0px ${theme.spacing.medium}`} />
                    <Subtitle>
                        <TextLoop mask={false} fade={true} interval={4000}>
                            <Label text='welcome.subtitle.1' margin={`0px 0px 0px 0px`} type={FontType.title} fontStyle={FontStyle.italicHeader1} color={ColorStyle.overImage} mode={ColorMode.light} align='center' />
                            <Label text='welcome.subtitle.2' margin={`0px 0px 0px 0px`} type={FontType.title} fontStyle={FontStyle.italicHeader1} color={ColorStyle.overImage} mode={ColorMode.light} align='center' />
                            <Label text='welcome.subtitle.3' margin={`0px 0px 0px 0px`} type={FontType.title} fontStyle={FontStyle.italicHeader1} color={ColorStyle.overImage} mode={ColorMode.light} align='center' />
                        </TextLoop>
                    </Subtitle>
                </Title>
            </TitleContainer>
        </>
    );
}

const BackgroundContainer = Style.div`
    height: 100vh;
    width: 100%;
    position: fixed;
    z-index: -10;
`;
const Logo = Style.img<{}>`
    height: 100px;
    top: 0;
    left: 0;
    ${props => !props.theme.desktop && 'right:0;'}
    position: absolute;
    margin-left: auto;
    margin-right: auto;
`;

const TitleContainer = Style.div`
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    /* align-content: space-around; */
    align-items: center;
`;

const Subtitle = Style.div`
    display: flex;
    flex-direction: column;
    align-content: space-around;
    align-items: center;
`;

const Title = Style.div`
    margin-top: auto;
    margin-bottom: auto;
`;

const Description = styled.div<{ theme: object }>`
    position: absolute;
    bottom: 0;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    max-width: ${props => props.theme.descriptionWidth};
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export default WelcomeSection;
