import ThemeProvider from './utils/theme/theme';
import OurTeamSection from './sections/OurTeamSection';
import OurClientsSection from './sections/OurClientsSection';
import ConversationSection from './sections/ConversationSection';
import ContactSection from './sections/ContactSection';
import OurMission from './sections/OurMission';
import WhatWeDoSection from './sections/WhatWeDoSection';
import FooterSection from './sections/FooterSection';
import PageOpaqueContainer from './components/PageOpaqueContainer';
import { BrowserRouter } from 'react-router-dom';
import Style from "styled-components";
import WelcomeSection from './sections/WelcomeSection';

const App = () => (
    <ThemeProvider>
        <BrowserRouter>
            <PageContainer>
                <WelcomeSection />
                <WhatWeDoSection />
                <OurMission />
                <PageOpaqueContainer>
                    <OurTeamSection />
                    <OurClientsSection />
                    <ConversationSection />
                    <ContactSection />
                    <FooterSection />
                </PageOpaqueContainer>
            </PageContainer>
        </BrowserRouter>
    </ThemeProvider>
);

const PageContainer = Style.div`
    justify-content: center;
    padding: 0;
    margin: 0 auto;
    height: 100%;
    width: 100%;
    display:table;
`;

export default App;