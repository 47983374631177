import React from 'react'
import Style from "styled-components";
import { ThemeContext } from "styled-components";

interface FooterSectionProps {
    children: any;
}

const FooterSection = ({ children } : FooterSectionProps ) => {
    const theme = React.useContext(ThemeContext);
    return (
        <Container theme={theme}>
            {children}
        </Container>
    );
};

interface ContentProps {
    theme: object;
}

const Container = Style.div<ContentProps>`
    padding:  ${props => props.theme.spacing.medium};
    margin: ${props => props.theme.spacing.small} ${props => props.theme.spacing.large};
    background: ${props => props.theme.color.foreground};
    position: relative;
    ${props => props.theme.shadow.light};
    overflow: hidden;
    border-radius: ${props => props.theme.borderRadius.regular};

    /* Auto Layout */
    flex-wrap: wrap;
    justify-content: center;
    display: flex;
`;

export default FooterSection;
