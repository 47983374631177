import React, { useRef, useState, useContext } from 'react'
import { ThemeContext } from "styled-components";
import Style from "styled-components";
import debounceFn from 'debounce-fn';

interface ImageCardSectionProps {
    children: any;
    imagePath: string;
}

const ImageCardSection = ({ children, imagePath }: ImageCardSectionProps) => {
    const theme = useContext(ThemeContext);

    const refContainer = useRef<HTMLDivElement>(null);

    const [marginAmount, setMarginAmount] = useState<number>(24);
    const [cornerRadiusAmount, setCornerRadiusAmount] = useState<number>(12);

    //Animation
    const cornerRadius = 24.0;
    const startRatio = 0.4;
    const endRatio = 0.6;

    // On event (cf. useLayoutEffect), update position on screen
    const updatePosition = () => {
        const viewportHeight = window.innerHeight || document.documentElement.clientHeight;

        // Set margin amount, based on ratio
        const ratio = (viewportHeight - (refContainer.current?.getBoundingClientRect().top || 0.0)) / viewportHeight;
        const progress = (ratio - startRatio) / (endRatio - startRatio)
        if (progress >= 0 && progress <= 1) {
            setMarginAmount((1 - progress) * 24);
            setCornerRadiusAmount((1 - progress) * cornerRadius);
        } else if (progress < 0) {
            setMarginAmount(24)
            setCornerRadiusAmount(cornerRadius);
        } else if (progress > 1) {
            setMarginAmount(0)
            setCornerRadiusAmount(0);
        }
    }

    React.useLayoutEffect(() => {
        const animate = debounceFn(updatePosition, {wait: 12})
        window.addEventListener('resize', animate);
        window.addEventListener('scroll', animate);
        updatePosition();
        return () => {
            window.removeEventListener('resize', animate);
            window.removeEventListener('scroll', animate);
        }
    }, []);

    return (
            <Container theme={theme} imagePath={imagePath} ref={refContainer} margin={marginAmount} borderRadius={cornerRadiusAmount}>
                {children}
            </Container>
    );
};

interface ContentProps {
    margin?: number;
    borderRadius?: number;
    theme?: object;
    imagePath?: string;
}

const Container = Style.div.attrs<ContentProps>( ({borderRadius, margin, theme}) =>
(
    { 
        style: {
            margin: `${theme.spacing.huge} ${margin}px ${theme.spacing.tiny} ${margin}px`,
            borderRadius: `${borderRadius}px`
        }
    }
    )
)<ContentProps>`
    background: url(${props => props.imagePath});
    background-size: cover;
    background-position: center;
    position: relative;
    overflow: hidden;
    ${props => props.theme.shadow.light};

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: ${props => props.theme.spacing.medium} 0px ${props => props.theme.spacing.extreme} 0px;
    z-index: 1;
    
`;

export default ImageCardSection;
