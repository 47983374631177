import ClientCard from './ClientCard';
import Style from 'styled-components';
import ImageCardSection from '../../components/ImageCardContainer';
import SectionHeader from '../../components/SectionHeader';
import { ColorMode, FontStyle } from '../../utils/types/types';

function OurClientsSection() {
    return (
        <ImageCardSection imagePath='./assets/background/our_clients.jpg'>
            <SectionHeader title='our_clients.header.title' subtitle='our_clients.header.subtitle' titleStyle={FontStyle.header1} mode={ColorMode.dark}></SectionHeader>
            <HorizontalStack>
                <ClientCard iconPath='./assets/clients/outpass.png' info='our_clients.outpass.client_info' body='our_clients.outpass.body'></ClientCard>
                <ClientCard iconPath='./assets/clients/sorbonne.png' info='our_clients.sorbonne.client_info' body='our_clients.sorbonne.body'></ClientCard>
                <ClientCard iconPath='./assets/clients/hooderie.png' info='our_clients.hooderie.client_info' body='our_clients.hooderie.body'></ClientCard>
            </HorizontalStack>
        </ImageCardSection>
    );
}

const HorizontalStack = Style.div<{}>`
    box-shadow: 0px 0px 64px rgb(0, 0, 0, 0);
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
    display: flex;
    position: relative;
`

export default OurClientsSection;
