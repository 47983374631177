import React from 'react'
import Style from "styled-components";
import { ThemeContext } from "styled-components";
import { ColorMode, ColorStyle, FontStyle, FontType } from '../../utils/types/types'
import Label from '../Label';

interface CardProps {
    title: string;
    iconPath: string;
    link: string;
}

const FloatingButton = ({ title, iconPath, link }: CardProps) => {
    const theme = React.useContext(ThemeContext);
    return (
        <Container theme={theme} href={link} target="_blank">
            <img alt="Creastel hires" src={iconPath} height={theme.spacing.body}></img>
            <Label text={title} margin="0px 0px 0px 8px" type={FontType.body} fontStyle={FontStyle.bigBody} color={ColorStyle.title} mode={ColorMode.dark} align='left'></Label>
        </Container>
    )
}

interface ContentProps {
    theme: object;
}

const Container = Style.a<ContentProps>`
    /* Auto Layout */

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: ${props => props.theme.spacing.small};

    position: fixed;
    left: ${props => props.theme.spacing.huge};
    bottom: ${props => props.theme.spacing.huge};

    /* Dark Card */

    ${props => props.theme.color.blueBlur};
    overflow: hidden;
    ${props => props.theme.shadow.light};
    border-radius: ${props => props.theme.borderRadius.regular} ${props => props.theme.borderRadius.regular} ${props => props.theme.borderRadius.regular} 0px;
    transition: 0.3s;
    z-index: 100;
    text-decoration: none;
    &:hover {
        transform: scale(1.02);
        ${props => props.theme.shadow.focused};
    }
    &:active {
        transform: scale(0.98);
        ${props => props.theme.shadow.focused};
    }
`;

export default FloatingButton;
