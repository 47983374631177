export enum CardSize {
    normal = 'normal',
    large = 'large',
}

export enum ColorMode {
    light = 'light',
    dark = 'dark',
}

export enum ColorStyle {
    contrast = 'contrast',
    title = 'title',
    subtitle = 'subtitle',
    body = 'body',
    info = 'info',
    overImage = 'overImage',
}

export enum FontType {
    title = 'title',
    body = 'body',
}

export enum FontStyle {
    italicHeader1 = 'italicHeader1',
    header1 = 'header1',
    header2 = 'header2',
    header3 = 'header3',
    header4 = 'header4',
    bigBody = 'bigBody',
    body = 'body',
    info = 'info',
}

export enum MessageStyle {
    send,
    receive,
}